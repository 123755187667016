<template>
  <div>
    <div class="policy-contaion">
      <div
        class="policy-title"
        v-if="type === 'home_stay_rent' || type === 'student_house_reserve'"
      >
        <van-image
          width="28"
          height="23"
          src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/title-policy.png"
        />
        <div class="title-right">
          VIP<span class="title-city-name">{{ cityName.name }}</span
          >{{
            type === "home_stay_rent"
              ? "定制租房服务"
              : type === "student_house_reserve"
              ? "学生公寓预定服务"
              : ""
          }}
        </div>
      </div>
      <div
        class="policy-service"
      >
        <div class="policy-left">
          <div class="policy-title">
            <van-image
              width="5"
              height="15"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/title-bar.png"
            />
            <div class="title-service">服务价目</div>
          </div>
        </div>
        <div class="policy-right">
          <div class="city">
            <van-image
              width="9"
              height="10"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/location.png"
            />
            <div class="city-name">{{ cityName.name }}</div>
          </div>
        </div>
      </div>
      <div
        style="margin-bottom: 15px"
        v-if="type === 'home_stay_rent' || type === 'student_house_reserve'"
      >
        <div
          class="house-list"
          v-for="(item, index) in serviceList"
          :key="'service' + index"
        >
          <div class="house-title">
            <!-- <div>{{item.maxValue==item.minValue?item.maxValue:item.maxValue-item.minValue}}居室</div> -->
            <div v-if="index < 3" class="house-title-desc">
              {{ item.description }}
            </div>
            <div v-else class="house-title-desc">4居室及以上</div>
            <!-- <div>{{item.maxValue}}居室</div> -->
            <div class="house-title-price">CNY {{ item.price }}</div>
          </div>
          <!-- <div class="house-content">{{item.description}}</div> -->
          <!-- <div class="house-content">
          海外整租或单间合租，主要为一室整租或与他们合租
        </div> -->
        </div>
      </div>
      <div
        style="margin-bottom: 15px"
        v-else
      >
        <div
          class="house-list"
          v-for="(item, index) in serviceList"
          :key="'service' + index"
        >
          <div class="house-title">
            <div class="house-title-desc">
              {{ item.description }}
            </div>
            <div class="house-title-price">CNY {{ item.price }}</div>
          </div>
        </div>
        </div>

      <!-- <div class="policy-pay">
        <div class="pay-service">
          欢迎选择以下方式支付 支持多国货币支付
        </div>
        <van-image  width="292px" height="19px"  :src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/pay.png')" />
         -->
      <div v-if="type === 'home_stay_rent'" class="pay-state">
        *若客户入住时间为下单日起14天,则需要在费用基础上追加CNY1,200至CNY3,600不等的加急处理费。
      </div>
      <!-- </div> -->
      <div class="policy-nav" v-if="problemList.length > 0">
        <div class="nav-title">常见问题</div>
      </div>
      <!-- 折叠手风琴 -->
      <van-collapse
        v-model="activeName"
        accordion
        v-if="problemList.length > 0"
      >
        <div v-for="(i, index) in problemList.slice(0,3)" :key="'problem' + index">
          <van-collapse-item
            v-if="problemList[index]"
            :title="problemList[index].title"
            :name="index + 1"
          >
            {{ i.content }}</van-collapse-item
          >
        </div>
      </van-collapse>
      <div v-if="problemList.length > 0" class="more" @click="moreProblemList">
        查看更多
      </div>

      <!-- 服务政策 -->
      <div class="policy-nav" v-if="servicePolicy.length > 0">
        <div class="nav-title">服务政策</div>
      </div>
      <div class="nav-list" v-if="servicePolicy.length > 0">
        <div class="cancelTitle">
          <!-- <van-image
            width="18"
            height="21"
            src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/logo.png"
          /> -->
          <div class="title-content">
            {{ servicePolicyTitle }}
          </div>
        </div>
        <div
          v-for="(item, idx) in servicePolicy.slice(0,3)"
          :key="idx"
          class="item-box"
          :class="idx === servicePolicy.length - 1 ? 'no-mb' : ''"
        >
          <div class="service-title">{{ item.label }}</div>
          <div class="service-content">{{ item.desc }}</div>
        </div>
      </div>
      <div
        v-if="servicePolicy.length > 0"
        class="more"
        @click="moreClickService"
      >
        查看更多
      </div>
      <!-- 退订政策 -->
      <div class="policy-nav" v-if="cancelPolicy.length > 0">
        <div class="nav-title">退订政策</div>
      </div>
      <div class="nav-list" v-if="cancelPolicy.length > 0">
        <div class="cancelTitle">
          <!-- <van-image
            width="18"
            height="21"
            src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/logo.png"
          /> -->
          <div class="title-content">
            {{ cancelTitle }}
          </div>
        </div>
        <div
          v-for="(i, index) in cancelPolicy.slice(0,3)"
          :key="'cancel' + index"
          class="item-box"
          :class="index === cancelPolicy.length - 1 ? 'no-mb' : ''"
        >
          <div class="service-title">{{ i.label }}</div>
          <div class="service-content">{{ i.desc }}</div>
        </div>
      </div>
      <div v-if="cancelPolicy.length > 0" class="more" @click="moreClick">
        查看更多
      </div>
    </div>
  </div>
</template>
<script>
import { getProductPriceInfo, cityNations } from "@/api/service";
export default {
  name: "Policy",
  data() {
    return {
      serviceList: [],
      cancelTitle: "",
      servicePolicyTitle: "",
      problemList: [],
      servicePolicy: [],
      activeName: 0,
      cityName: {},
      cancelPolicy: [],
      productId: undefined,
      nationId: undefined,
      type: "",
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    this.nationId = this.$route.query.nationId;
    this.type = this.$route.query.key;
    console.log("this.productId结果===>", this.productId);
    console.log("this.type结果===>", this.type);
    console.log("this.nationId结果===>", this.nationId);
    this.cityNations();
    this.onload();
  },
  methods: {
    cityNations() {
      cityNations({ nationId: this.nationId }).then((res) => {
        // console.log('res结果===>',res)
        this.cityName = res.data[0];
      });
    },
    onload() {
      getProductPriceInfo({ productId: this.productId }).then((res) => {
        let data = res.data;
        console.log("getProductPriceInfo结果===>", data);
        if (data.serviceList && data.serviceList.length > 0) {
          this.serviceList = data.serviceList;
        }
        if (data.faqList && data.faqList.length > 0) {
          this.problemList = data.faqList;
        }
        if (data.cancelPolicy && data.cancelPolicy.cancelContent.length > 0) {
          this.cancelPolicy = data.cancelPolicy.cancelContent;
          this.cancelTitle = data.cancelPolicy.cancelTitle;
        }
        if (
          data.servicePolicy &&
          data.servicePolicy.serviceContent.length > 0
        ) {
          this.servicePolicy = data.servicePolicy.serviceContent;
          this.servicePolicyTitle = data.servicePolicy.serviceTitle;
        }
      });
    },
    moreClickService() {
      enterServicePolicy.postMessage(
        JSON.stringify({
          productId: this.productId,
        })
      );
    },
    moreClick() {
      enterCancelPolicy.postMessage(
        JSON.stringify({
          productId: this.productId,
        })
      );
    },
    moreProblemList() {
      enterCommonQuestion.postMessage(
        JSON.stringify({
          productId: this.productId,
        })
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.cancelTitle {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.title-content {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.policy-contaion {
  padding: 23px 15px;
  height: 100%;
}
.policy-title {
  display: flex;
  align-items: center;
}
.title-right {
  font-size: 20px;
  color: #333333;
  letter-spacing: 1px;
  margin-left: 6px;
  font-weight: bold;
}
.title-right .title-city-name {
  color: #f67f58;
}
.policy-service {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.title-service {
  font-size: 14px;
  margin-left: 10px;
}
.policy-right {
  display: flex;
}
.city {
  background: #f67f58;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 3px 5px;
}
.city-name {
  font-size: 12px;
  margin-left: 3px;
  color: #fff;
}

.house-list {
  padding: 0 15px;
  background: rgba(246, 127, 88, 0.1);
  margin: 20px 0;
  border-radius: 5px;
}
.house-title {
  // padding-top: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #3e3a39;
  font-weight: bold;
}
.house-title-desc {
  font-size: 16px;
  font-weight: bold;
  color: #3e3a39;
}
.hosue-title-price {
  font-size: 16px;
  font-weight: bold;
  color: #3e3a39;
}
.house-content {
  font-size: 12px;
  margin: 10px 0;
}
.policy-pay {
  margin-top: 10px;
  padding-left: 20px;
}
.pay-service {
  font-size: 14px;
  margin: 20px 0;
  color: #3e3a39;
  letter-spacing: 1px;
}
.pay-state {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 12px;
  color: #595757;
  letter-spacing: 1px;
}
.policy-nav {
  // margin-top: 30px;
  margin-bottom: 20px;
}
.nav-title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
.nav-content {
  font-size: 14px;
  background: #f2f2f2;
  height: 40px;
  border-radius: 3px;
  align-items: center;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  letter-spacing: 1px;
  margin: 10px 0;
  color: #666666;
}
.answe {
  margin-top: 10px;
  padding: 10px 15px;
  background: #f2f2f2;
  border-radius: 3px;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 1px;
}
/deep/.van-icon-arrow::before,
.van-icon-arrow-down::before {
  font-size: 18px;
}
.nav-list {
  background: #f2f2f2;
  border-radius: 5px;
  padding: 20px 15px;
  // padding-bottom: 0;
  margin-bottom: 20px;
}
.service-title {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  font-weight: bold;
}
.service-desc {
  margin-bottom: 20px;
}
.service-content {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  // margin: 12px 0 10px 0;
  margin-top: 12px;
}
.more {
  font-size: 14px;
  color: #f67f58;
  margin-top: 10px;
  margin-bottom: 30px;
}
/deep/.van-collapse-item__content {
  background-color: #f2f2f2;
  color: #666666;
}
/deep/.van-cell--clickable {
  background-color: #f2f2f2;
  color: #666666;
}
/deep/.van-hairline--top-bottom {
  > div {
    margin-bottom: 20px;
  }
}
.item-box {
  margin-bottom: 30px;
}
.no-mb {
  margin-bottom: 0;
}
</style>
